import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
// import styles from './css/Header.css';
import './css/Header.css';
import { faPaw, faGamepad } from '@fortawesome/free-solid-svg-icons';

let activeStyle = {
  backgroundColor: '#9edacb',
};

const Header = () => {
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand-lg stick-navbar">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li>
              <NavLink
                className="nav-link text-center"
                to="/"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                HOME
              </NavLink>
            </li>

            <li>
              <NavLink
                className="nav-link text-center"
                to="/about"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                ABOUT
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link text-center"
                to="/contact"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                CONTACT
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link text-center"
                to="/pets"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <FontAwesomeIcon icon={faPaw} />
              </NavLink>
            </li>
            {/* <li>
                <NavLink
                  className="nav-link"
                  to="/three"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  3
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  to="/three2"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  3 2
                </NavLink>
              </li> */}
            <li>
              <NavLink
                className="nav-link text-center"
                to="/ModelDisplay"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <FontAwesomeIcon icon={faGamepad} />
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
