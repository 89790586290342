import './App.css';
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/layout/Header';
import Home from './components/layout/Home';
import About from './components/layout/About';
import Contact from './components/layout/Contact';
import Pets from './components/layout/Pets';
import ThreeLayout from './components/layout/Three';
import Three2Layout from './components/layout/Three2';
import ModelDisplayLayout from './components/layout/ModelDisplay';

function App() {
  return (
    <div className="App">
      <Router>
        <Fragment>
          <Header />
          <main id="main">
            <section className="container-fluid">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/pets" element={<Pets />} />
                <Route exact path="/three" element={<ThreeLayout />} />
                <Route exact path="/three2" element={<Three2Layout />} />
                <Route
                  exact
                  path="/ModelDisplay"
                  element={<ModelDisplayLayout />}
                />
                {/* <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} /> */}
              </Routes>
            </section>
          </main>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
