// import { createRoot } from 'react-dom/client';
import React from 'react';
import ModelCard from './ModelCard';

// Define models to use
const models = [
  {
    id: 1,
    name: 'Mole pet (santa)',
    modelName: 'Mole',
    description:
      'The Molepet that you can receive from the Mole boss from Old School Runescape.',
    cameraPosition: [75, 150, 500],
    zoom: 2.45,
  },
  {
    id: 2,
    name: 'King Black Dragon',
    modelName: 'KingBlackDragonCentered',
    description: 'The King Black Dragon boss from Old School Runescape.',
    cameraPosition: [55, 80, 500],
    zoom: 0.55,
  },
  {
    id: 3,
    name: 'Alchemical Hydra',
    modelName: 'AlchemicalHydraCentered',
    description: 'The Alchemical Hydra boss from Old School Runescape.',
    cameraPosition: [75, 150, 500],
    zoom: 0.35,
  },
  {
    id: 4,
    name: 'Astronaut',
    modelName: 'Astronaut',
    description: 'Astronaut model',
    cameraPosition: [55, 80, 500],
    zoom: 0.55,
  },
];

var modelsToShow = [];

models.forEach((modelAtIndex) => {
  modelsToShow.push(
    <ModelCard key={modelAtIndex.id} modelDetails={modelAtIndex} />
  );
});

function ModelDisplayLayout() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <h4>Models</h4>
        <div className="row">
          <div className="d-flex align-content-start flex-wrap">
            {modelsToShow}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelDisplayLayout;
