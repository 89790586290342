import React from 'react';
import './css/Home.css';

import ProjectCard from './ProjectCard';
const Home = () => {
  const urls = {
    github: 'https://github.com/jamescer',
    linkedin: 'https://www.linkedin.com/in/jamescerniglia/',
    twitter: 'https://twitter.com/BIG_SQUEEZER',
  };

  const projects = [
    // {
    //   _id: '5f0e4ef4a1b18535a0bbc253',
    //   id: 'os.gg',
    //   name: 'OS Helper',
    //   developers: ['James Cerniglia'],
    //   skillsUsed: ['node', 'vue', 'mongo', 'express'],
    //   imgUrl: './assets/projects/osgg2.png',
    //   codeUrl: 'https://github.com/jamescer/os.gg',
    //   liveUrl: 'https://osgg.netlify.app/',
    //   likes: 174,
    //   liveBool: true,
    //   sourceBool: true,
    //   description:
    //     'Web App that calls the Old School Runescape Hiscores and displays account ranking and information. Allows players to see what they are able to do at their current skill level.',
    //   longDescription: '',
    //   clicked: false,
    // },
    // {
    //   _id: '5f0e4f192be6b223582558d2',
    //   id: 'cornerstone',
    //   name: 'Cornerstone Management Software',
    //   developers: ['James Cerniglia', 'Stefan', 'Amber'],
    //   skillsUsed: ['python', 'kivy', 'mysql'],
    //   imgUrl: './assets/projects/cornerstone.png',
    //   liveBool: false,
    //   sourceBool: false,
    //   likes: 35,
    //   codeUrl: 'https://github.com/Stefunga/CSINC',
    //   liveUrl: '',
    //   liveUrlReason: 'Code not disclosed due to NDA.',
    //   description:
    //     'Employee Management Software developed for https://crst.net.',
    //   clicked: false,
    // },
    {
      id: 'oldschooltools',
      name: 'Old School Tools',
      developers: ['James Cerniglia'],
      skillsUsed: ['Javascript', 'Node'],
      clicked: false,
      liveBool: true,
      sourceBool: true,
      description:
        'Node package made for developers to easily access organized data from Old School RuneScape.',
      likes: 12,
      codeUrl: 'https://github.com/jamescer/osrs-tools',
      liveUrl: 'https://www.npmjs.com/package/osrs-quest-tool',
      longDescription: '',
      imgUrl: './assets/projects/hydra2.png',
    },
  ];

  var projectsShown = [];

  projects.forEach((project1) => {
    projectsShown.push(<ProjectCard key={project1.id} project={project1} />);
  });


  return (
    <div id="home">
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className="col-3 m-1">
            <img src="./img/me.jpg" className="pic-of-me" alt="me" />
          </div>
          <div className="col-4 m-1">
            <h2 id="title">James Cerniglia</h2>
            <h4 id="description">Software Engineer II</h4>
            <div className="landingButtons d-flex justify-content-center">
              <button
                className="btn m-1 text-white project-btn rounded"
                type="button"
                id="projectBtn"
                href="#/projects"
              >
                View Projects
              </button>
              <button
                className="btn m-1 profile-btn rounded"
                id="resumeBtn"
                type="button"
                href="../JamesCernigliaResume.pdf"
              >
                View Resume
              </button>
            </div>

            <div className="d-flex justify-content-center mt-4 social-icon-wrapper">
              <a className="social-icon" href={urls.github}>
                <i className="fab fa-github fa-2x " />
              </a>
              <a className="social-icon" href={urls.linkedin}>
                <i className="fab fa-linkedin fa-2x " />
              </a>
              <a className="social-icon" href={urls.twitter}>
                <i className="fab fa-twitter-square fa-2x " />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="projects-container">
        <h4 className="text-center">Projects</h4>
        <div className="row">
          <div className="d-flex align-content-start flex-wrap">
            {projectsShown}
            {/* {projects.map((p) => (
                <ProjectCard  project={p}></ProjectCard> 
                // key={p.id}
              ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
