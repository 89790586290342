import './css/Three.css';
import * as THREE from 'three';
// import { createRoot } from 'react-dom/client';
import React, { useRef, useState, Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { Text, OrbitControls } from '@react-three/drei';

function TextTest() {
  // var test1 = new THREE.TextGeometry('Hello three.js!', { size: 1 });
  return (
    <Suspense>
      <Canvas>
        {/* <test1 /> */}
        <Text
          scale={[10, 10, 10]}
          color="black" // default
          anchorX="center" // default
          anchorY="middle" // default
        >
          HELLO WORLD
        </Text>
        <OrbitControls />
        {/* <Stats /> */}
      </Canvas>
    </Suspense>
  );
}

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.y += 0.01));
  useFrame((state, delta) => (ref.current.rotation.x += 0.01));

  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'green' : 'blue'} />
    </mesh>
  );
}

const loader = new THREE.FileLoader();
console.log(loader);
THREE.Cache.enabled = true; // cache must be enabled for this to work

const ThreeLayout = () => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const cube = new THREE.Mesh(geometry, material);
  scene.add(cube);

  camera.position.z = 5;
  function animate() {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
  }

  animate();
  return (
    <div className="container-fluid">
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} />
      </Canvas>
      <div className="m-4">
        <TextTest />
      </div>
    </div>
  );
};

export default ThreeLayout;
