import React from 'react';
import './css/ModelCard.css';
import ModelViewerLayoutParam from './ModelViewerLayoutParam';

const ModelCard = (props) => {
  console.log('modelDetails', props);
  var modelInfo = props.modelDetails;
  return (
    <div className="card model-card  m-2">
      <ModelViewerLayoutParam
        className="model-card-top"
        modelDetails={modelInfo}
        alt={modelInfo.id}
      />
      <div className="card-body">
        <h5 className="card-title">{modelInfo.name}</h5>
        <p className="card-text">{modelInfo.description}</p>
      </div>
    </div>
  );
};

export default ModelCard;
