import './css/ModelViewerLayoutParam.css';
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, OrbitControls, OrthographicCamera } from '@react-three/drei';

/**
 * Inputs a string of a local 3d Model, returns a simple canvas with the object
 */
const modelPath = '/assets/models/';
const modelExtension = '.glb';

/**
 *
 * @returns
 */
function ModelByName(props) {
  const gltf = useGLTF(modelPath + props.name + modelExtension);
  return <primitive object={gltf.scene} />;
}

//https://stackoverflow.com/questions/71589738/how-do-i-properly-use-drei-usegltf
/**
 * Creates a canvas, camera, ambient light and imports a model located in the public assets of this project
 * @returns
 */
// TODO make this setting from model array
var ModelViewerLayoutParam = (props) => {
  var modelInfo = props.modelDetails;
  return (
    <Suspense>
      <Canvas className="model-canvas">
        <OrthographicCamera
          makeDefault
          zoom={modelInfo.zoom}
          position={modelInfo.cameraPosition}
        />
        <OrbitControls />
        <ambientLight />
        <ModelByName name={modelInfo.modelName} />
      </Canvas>
    </Suspense>
  );
};

export default ModelViewerLayoutParam;
