import './css/Three.css';
import React, { useRef, useState, Suspense } from 'react';
import { Canvas, useLoader, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, OrthographicCamera } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
function Model1() {
  const gltf = useGLTF('/assets/models/Mole.glb');
  return <primitive object={gltf.scene} />;
}

const ModelObject = ({ modelPath }) => {
  const { gltf } = useGLTF(modelPath);
  return <primitive object={gltf.scene} dispose={null} />;
};
console.log(ModelObject);
// Load .obj files into react scene
function Scene1() {
  const obj = useLoader(
    OBJLoader,
    '/NPC_Prospector_Percy_2023-01-02_11-55-04.obj'
  );
  return <primitive object={obj} />;
}

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);

  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.y += 0.01));
  useFrame((state, delta) => (ref.current.rotation.x += 0.01));

  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'green' : 'blue'} />
    </mesh>
  );
}
console.log('Box', Box);
//https://stackoverflow.com/questions/71589738/how-do-i-properly-use-drei-usegltf
/**
 * Creates a canvas, camera, ambient light and imports a model located in the public assets of this project
 * @returns
 */
function Three2Layout_fromStack() {
  return (
    <div className="container-fluid">
      <Suspense>
        <Canvas>
          <OrthographicCamera makeDefault position={[0, 0, 100]} />
          <OrbitControls />
          <ambientLight />
          <Model1 />
        </Canvas>
      </Suspense>
    </div>
  );
}

export default Three2Layout_fromStack;
