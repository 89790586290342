/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import PetCard from './PetCard';
import './css/Pets.css';
const pets = [
  {
    id: 0,
    name: 'Finn',
    description: 'He was a good boy',
    images: ['1.jpg', '2.jpg', '3.jpg'],
  },
  { id: 1, name: 'Tobey', description: 'He is a good boy', images: ['1.jpg', '2.jpg']},
  { id: 2, name: 'Remy', description: 'He is a good boy',  images: ['1.jpg', ]},{
    id: 4,
    name: 'Sparky',
    description:
      'Sparky was a young white and grey, tabby cat and was a good boy',
    images: [],
  },
  { id: 5, name: 'Mittens', description: ' is a good girl.', images: [] },
  { id: 6, name: 'Teddie', description: ' is a good girl.', images: [] },
];
var petsToShow = [];
pets.forEach((pet) => {
  petsToShow.push(<PetCard key={pet.id} pet={pet} />);
});

const PetsComponent = () => {
  return (
    <div className="mt-1">
      <div className="row">
        <h3> Page for the pets =) </h3>
      </div>
      <div className="pet-sections">
        {pets.map((pet) => (
          <section key={pet.id} className="pet-section">
            <div className="pet-content">
              <h3>{pet.name}</h3>{`/assets/Pets/${pet.name}`}
              <p>{pet.description}</p>
              <div className="image-gallery">
                <div className="scroll-container">
                  {pet.images.map((image, index) => (
                    <div key={`${pet.name} ${image}`} className="image-item">
                     <img
                        src={`/assets/Pets/${pet.name}/${image}`}
                        alt={`${pet.name} Image ${index + 1}`}
                        className="gallery-image"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>

      {/* <div>
        <div className="row">
          <div className="col">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#indicator0"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li data-target="#indicator1" data-slide-to="1"></li>
                <li data-target="#indicator2" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item" key="Finn12">
                  <img
                    className="d-block w-100"
                    src="/assets/pets/Finn/1.jpg"
                    alt="test"
                  />
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
          <div className="col">
            <p> </p>
          </div>
        </div>
      </div>
      <CarouselComponent /> */}
      {/* <div> {petsToShow} </div> */}
    </div>
  );
};

export default PetsComponent;
