import React from 'react';
import './css/Footer.css';

const Footer = () => {
  const urls = {
    github: 'https://github.com/jamescer/',
    linkedin: 'https://www.linkedin.com/in/jamescerniglia/',
    twitter: 'https://twitter.com/BIG_SQUEEZER',
    angel: 'https://angel.co/u/james-cerniglia',
    twitch: 'https://twitch.tv/BIG_SQUEEZER',
    insta: 'https://www.instagram.com/james.cer/',
    facebook: 'https://www.facebook.com/james.cerniglia/',
  };
  return (
    <div className="pt-2">
      <footer className="footer row">
        <div className="col-10 ">
          <div id="icons">
            <a className="icon" href={urls.facebook}>
              <i className="fab fa-facebook-square fa-2x "></i>
            </a>
            <a className="icon" href={urls.insta}>
              <i className="fab fa-instagram fa-2x "></i>
            </a>
            <a className="icon" href={urls.twitch}>
              <i className="fab fa-twitch fa-2x "></i>
            </a>
            <a className="icon" href={urls.linkedin}>
              <i className="fab fa-linkedin fa-2x "></i>
            </a>
            <a className="icon" href={urls.twitter}>
              <i className="fab fa-twitter-square fa-2x "></i>
            </a>
            <a className="icon" href={urls.github}>
              <i className="fab fa-github fa-2x "></i>
            </a>
            <a className="icon" href={urls.angel}>
              <i className="fab fa-angellist fa-2x "></i>
            </a>
          </div>
        </div>
        <div className="col-2 name">
          <p className="ftrtxt">
            {' '}
            <i className="far fa-copyright"></i> James Cerniglia
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
