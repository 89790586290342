import React from 'react';
import './css/ProjectCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLaptop, faHeart } from '@fortawesome/free-solid-svg-icons';

function getIcon(skill) {
  var i = skill.toLowerCase();
  if (i === 'mysql') {
    return (
      <span className="badge mysqlBadge m-1">
        <img alt="mysql" className="codeIcon" src="./assets/icons/mysql.png" />
        MySQL
      </span>
    );
  } else if (i === 'python') {
    return (
      <span className="badge python-badge m-1">
        <img
          alt="python"
          className="codeIcon"
          src="./assets/icons/python.png"
        />
        Python
      </span>
    );
  } else if (i === 'kivy') {
    return (
      <span className="badge kivyBadge m-1">
        <img alt="kivy" className="codeIcon" src="./assets/icons/kivy.png" />
        Kivy
      </span>
    );
  } else if (i === 'node') {
    return (
      <span className="badge nodeBadge m-1">
        <img alt="node" className="codeIcon" src="./assets/icons/nodejs.png" />
        Node
      </span>
    );
  } else if (i === 'vue') {
    return (
      <span className="badge vueBadge m-1">
        <img alt="vue" className="codeIcon" src="./assets/icons/vue.png" />
        Vue
      </span>
    );
  } else if (i === 'mongo') {
    return (
      <span className="badge mongoBadge m-1">
        <img
          alt="mongo"
          className="codeIcon"
          src="./assets/icons/mongodb.png"
        />
        Mongo
      </span>
    );
  }

  return;
}

const ProjectCard = (props) => {
  var project = props.project;

  return (
    <div className="project-card m-2">
      {/* key={project.name} */}
      <img
        className="card-img-top project-img"
        src={project.imgUrl}
        alt={project.imgUrl}
      />
      <div className="card-body project-card-body">
        <div className="card-top">
          <div className="project-title">{project.name}</div>
        </div>
        <p className="card-text project-description">{project.description}</p>
        <hr className="hrStyle1" />
        <div className="d-flex justify-content-center">
          <div id="icons">
            {project.skillsUsed.map((index) => getIcon(index))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn project-like-btn m-1" id={project.id}>
          {project.likes} <FontAwesomeIcon icon={faHeart} />
        </button>
        <button
          href={project.liveUrl}
          className={
            project.liveBool
              ? 'btn project-demo-btn disabled m-1'
              : 'btn project-demo-btn m-1'
          }
        >
          Demo <FontAwesomeIcon icon={faLaptop} />
        </button>
        <button
          href={project.codeUrl}
          className={
            project.sourceBool
              ? 'btn project-code-btn  m-1'
              : 'btn project-code-btn disabled m-1'
          }
        >
          Source <FontAwesomeIcon icon={faCode} />
        </button>
      </div>
    </div>
  );
};

export default ProjectCard;
