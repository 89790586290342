import React from 'react';

/**
 * Takes an input of a pet return img tags with pet images
 * @param {} pet
 */
function carouselImages(params) {
  let imagesArr = [];
  var pet = params.pet;
  console.log('CarouselImages ', pet);
  if (pet.images) {
    for (var index1 in pet.images) {
      var imageJson = pet.images[index1];
      console.log('imageJson', imageJson);

      imagesArr.push(
        <div className="carousel-item" key={imageJson.description}>
          <img
            className="d-block w-100"
            src={imageJson.srcUrl}
            alt={imageJson.description}
          />
        </div>
      );
    }
  }
  return imagesArr;
}

const PetCard = (modelDetails) => {
  console.log('PetCard Constructor', modelDetails);
  return (
    <div className="row">
      <div className="col">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#indicator0"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#indicator1" data-slide-to="1"></li>
            <li data-target="#indicator2" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">{carouselImages(modelDetails)}</div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      <div className="col">
        <p>
          {modelDetails.name} {modelDetails.description}
        </p>
      </div>
    </div>
  );
};

export default PetCard;
