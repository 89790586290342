import React from 'react';
import Footer from './Footer';

const Contact = () => {
  return (
    <div className="mt-1">
      <div className="row">
        <h3>Contact Me</h3>
        <hr />
      </div>
      <div className="row">
        <div className="col">
          <p>
            Feel free to reach out to me at{' '}
            <a href="mailto:jamesmcerniglia@gmail.com?subject=Reaching%20Out&body=Hi%20James%21%0A%0AI%20would%20love%20to%20get%20a%20chance%20to%20speak%20with%20you%21%0A">
              jamesmcerniglia@gmail.com
            </a>
          </p>
        </div>
        <div className="col"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
