import React from 'react';

const About = () => {
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    fetch('/api/about')
      .then((res) => res.json())
      .then((data) => setData(data.message));
  });

  return (
    <div>
      <h1>About Page</h1>
      <p>{data}</p>
    </div>
  );
};

export default About;
